import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—March 2010" />
	<NewsHeader />
    <h2>March 2010</h2>



	<h3 className="blogdate">
	Wednesday, March 17, 2010
	</h3>

	<h4 className="blogitemtitle">
	FREE digital double live album released
	</h4>
	   <div className="blogitembody">
	   <p>I'm excited to announce that I've just released a FREE gift to everyone -
	it was given to me and, after some processing and super-fying, I am
	re-gifting it to any and all through the Internet. It's a digital double
	live album called:</p>

	<ul>
	<li>
	Jesse Rivest - Live At The Mussel Inn - Feb 19 2010
	</li>
	<li>
	(<Link to="/music/02/">click here to read more</Link>)
	</li>
	</ul>
	<p>That's it! The official release date was yesterday, March 16, 2010, when I
	made it public via Facebook and Twitter. I hope you enjoy, and please
	forward this on to your friends and acquaintances around the Earth!
</p>
	<p>
	Thanks,
	</p>
	<p>
	Jesse
	</p>
	   </div>
	   	   <div className="blogitemfooter">
	   <p>posted by Jesse @ 15:16 +1300</p>
	   </div>




	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
